var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:{
  'is-active': _vm.name === _vm.$route.name
}},[_c('router-link',_vm._b({attrs:{"to":{
      name: _vm.name,
      params: _vm.params,
      query: _vm.query,
    }}},'router-link',{exact: _vm.exact},false),[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }