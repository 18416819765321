<template>
<li :class="{
  'is-active': name === $route.name
}">
  <router-link 
    v-bind="{exact}" 
    :to="{
      name: name,
      params: params,
      query: query,
    }">
    <slot />
  </router-link>
</li>
</template>
<script>
export default {

  props: {
    name: String,
    params: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    },
    exact: {
      type: Boolean,
      default: false
    }
  }

}
</script>